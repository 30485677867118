import { graphql, useStaticQuery } from "gatsby"

export const usePropertyCardData = (propertyIds: number[]): readonly Queries.TampuuriPropertyCardFragment[] => {
  const data = useStaticQuery<Queries.TampuuriPropertyCardsQuery>(
    graphql`
      query TampuuriPropertyCards {
        tampuuri {
          property {
            ...TampuuriPropertyCard
          }
        }
      }
    `
  )

  const properties = data.tampuuri.property
  if (!properties) {
    throw new Error("Failed to fetch list of properties")
  }

  // console.debug("Filtering properties", propertyIds)

  return properties
}

export const query = graphql`
  fragment TampuuriPropertyCard on Tampuuri_property {
    id
    street_address
    zip_code
    hood
    slug
    free_apartments
    housing_types
    rent_min # new for HoodPage
    rent_max # new for HoodPage
    free_apartments # new for HoodPage
    media(where: { category: { _in: [facade, marketing] } }, limit: 1, order_by: { position: asc, size: desc }) {
      id
      url
    }
  }
`
