import { graphql } from "gatsby"
import React from "react"
import PoriIcon from "../../atoms/PoriIcon"
import Linkki from "../../molecules/Linkki"
import IconCard from "../../molecules/cards/IconCard"

type ContentIconLinkProps = Queries.ContentIconLinkFragment

const ContentIconLink = (props: ContentIconLinkProps) => {
  // console.debug("Rendering ContentIconLink", props)

  if (!props.target) {
    return null
  }

  if (props.icon === null || props.linkLabel === null) {
    return null
  }

  const icon = <IconCard icon={<PoriIcon name={props.icon} />} label={props.linkLabel} />

  if (props.target.id) {
    return <Linkki id={props.target.id}>{icon}</Linkki>
  } else if (props.target.url) {
    return (
      <a href={props.target.url} target="_blank" rel="noopener noreferrer">
        {icon}
      </a>
    )
  }
  return null
}

export default ContentIconLink

export const query = graphql`
  fragment ContentIconLink on DatoCmsIconLink {
    id
    icon
    linkLabel
    target {
      ...ContentRoute
      ...ContentExternalUrl
    }
  }
`
