import { graphql } from "gatsby"
import React from "react"
import ContentApplication from "./modular-blocks/ContentApplication"
import ContentCtaButtonIconSection from "./modular-blocks/ContentCtaButtonIconSection"
import ContentCtaCardSection from "./modular-blocks/ContentCtaCardSection"
import ContentImage from "./modular-blocks/ContentImage"
import ContentPropertyCardSection from "./modular-blocks/ContentPropertyCardSection"
import ContentResidentInfoSection from "./modular-blocks/ContentResidentInfoSection"
import ContentTextSection from "./modular-blocks/ContentTextSection"

type HoodPageContentProps = Queries.DatoHoodPageContentFragment

const HoodPageContent = (props: HoodPageContentProps) => {
  // console.debug("Rendering HoodPageContent", props)

  const { content } = props
  if (!content) {
    console.warn("Invalid props for HoodPageContent")
    return null
  }

  return (
    <>
      {content.map(field => {
        if (field === null) return null
        switch (field.__typename) {
          case "DatoCmsCtaButtonIconSection":
            return <ContentCtaButtonIconSection key={field.id} {...field} />
          case "DatoCmsCtaCardSection":
            return <ContentCtaCardSection key={field.id} {...field} />
          case "DatoCmsTextSection":
            return <ContentTextSection key={field.id} {...field} />
          case "DatoCmsImage":
            return <ContentImage key={field.id} {...field} />
          case "DatoCmsResidentInfoSection":
            return <ContentResidentInfoSection key={field.id} {...field} />
          case "DatoCmsPropertyCardSection":
            return <ContentPropertyCardSection key={field.id} {...field} />
          case "DatoCmsApplication":
            return <ContentApplication key={field.id} {...field} />
        }
      })}
    </>
  )
}

export default HoodPageContent

export const query = graphql`
  fragment DatoHoodPageContent on DatoCmsHoodpage {
    content {
      ... on Node {
        __typename
        id
      }
      ...ContentTextSection
      ...ContentCtaButtonIconSection
      ...ContentCtaCardSection
      ...ContentImage
      ...ContentResidentInfoSection
      ...ContentPropertyCardSection
      ...ContentApplication
    }
  }
`
