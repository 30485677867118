import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import { Button } from "../../atoms/button/Button"
import Linkki from "../../molecules/Linkki"
import Markdown from "../markdown/Markdown"
import * as styles from "./ContentCtaCard.module.scss"

type ContentCtaCardProps = Queries.ContentCtaCardFragment

const ContentCtaCard = (props: ContentCtaCardProps) => {
  // console.debug("Rendering ContentCtaCard", props)
  const { paragraphNode, image, title, linkLabel, target } = props
  const htmlAst = paragraphNode?.childMarkdownRemark?.htmlAst

  return (
    <div className={styles.container}>
      <div className={styles.imageWrapper}>
        {image?.gatsbyImageData && (
          <GatsbyImage style={{ height: "100%" }} alt={image.alt || ""} image={image.gatsbyImageData} />
        )}
      </div>

      <div className={styles.contentWrapper}>
        <h3>{title}</h3>
        {htmlAst && <Markdown headingShift={2} htmlAst={htmlAst} />}
        {linkLabel && (
          <Linkki {...target}>
            <Button label={linkLabel} variant="primary" displayArrow />
          </Linkki>
        )}
      </div>
    </div>
  )
}

export default ContentCtaCard

export const query = graphql`
  fragment ContentCtaCard on DatoCmsCtaCard {
    __typename
    id
    image {
      alt
      # fluid(imgixParams: { fit: "crop", w: "1000", h: "800" }) {
      #  ...GatsbyDatoCmsFluid
      # }
      gatsbyImageData(layout: FULL_WIDTH, width: 1000, height: 800)
    }
    title
    paragraphNode {
      ...MarkdownRemark
    }
    linkLabel
    target {
      ...ContentRoute
      ...ContentExternalUrl
    }
  }
`
