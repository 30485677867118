import { graphql } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import HoodPageContent from "../dato/HoodPageContent"
import HeroImage from "../organisms/HeroImage"
import PropertyCardList from "../organisms/PropertyCardList"

type HoodPageProps = {
  page: Queries.DatoHoodPageFragment
  locale: string
  propertyTypes: [
    { housingType: "rental"; properties: readonly Queries.TampuuriPropertyCardFragment[] },
    { housingType: "student"; properties: readonly Queries.TampuuriPropertyCardFragment[] },
    { housingType: "senior"; properties: readonly Queries.TampuuriPropertyCardFragment[] }
  ]
}

/**
 * Asuinalue-sivu
 */
const HoodPage = (props: HoodPageProps) => {
  // console.debug("Rendering template HoodPage", props)
  const { page, locale, propertyTypes } = props
  const { content, hood, title, ingress, image, mobileImage } = page
  const { t } = useTranslation("asuntosivu")

  const basePath = locale === "en" ? "/en/housing" : "/fi/kohteet"

  // const [searchState, setTampuuriSearchParams] = useApartmentSearch()

  // useEffect(() => {
  //   if (!hood) {
  //     return
  //   }
  //   setTampuuriSearchParams({ hood })
  // }, [hood])

  if (!content || !hood) {
    console.warn("Invalid props for HoodPage")
    return null
  }

  return (
    <>
      <HeroImage image={image} mobileImage={mobileImage} />

      {title && <h1>{title}</h1>}
      {ingress && <p className="ingress">{ingress}</p>}

      {propertyTypes.map(propertyType => (
        <div key={propertyType.housingType}>
          <h2>{t(`${propertyType.housingType}Plural`)}</h2>
          <section>
            <PropertyCardList properties={propertyType.properties} basePath={basePath} />
          </section>
        </div>
      ))}
      <HoodPageContent content={content} />
    </>
  )
}

export default HoodPage

export const query = graphql`
  fragment DatoHoodPage on DatoCmsHoodpage {
    hood
    title
    ingress
    image {
      ...DatoHeroImage
    }
    mobileImage {
      ...DatoHeroMobileImage
    }
    ...DatoHoodPageContent
  }

  fragment HoodProperties on Tampuuri_property {
    ...TampuuriPropertyCard
  }
`
