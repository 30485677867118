import { PageProps, graphql } from "gatsby"
import React from "react"
import DefaultLayout from "../components/layouts/DefaultLayout"
import HoodPage from "../components/pages/HoodPage"

const HoodPageTemplate = (props: PageProps<Queries.HoodPageTemplateQuery, Queries.HoodPageTemplateQueryVariables>) => {
  const { data, pageContext } = props
  const { mainNavData, page, tampuuri } = data

  if (!mainNavData || !page) {
    return null
  }

  const { seoMetaTags, ...pageProps } = page

  return (
    <DefaultLayout mainNav={mainNavData} seoMetaTags={seoMetaTags ?? undefined}>
      <HoodPage
        page={pageProps}
        locale={pageContext.locale}
        propertyTypes={[
          { housingType: "rental", properties: tampuuri.rentalProperties },
          { housingType: "student", properties: tampuuri.studentProperties },
          { housingType: "senior", properties: tampuuri.seniorProperties },
        ]}
      />
    </DefaultLayout>
  )
}

export default HoodPageTemplate

export const query = graphql`
  query HoodPageTemplate($hood: String!, $locale: String!) {
    page: datoCmsHoodpage(locale: { eq: $locale }, hood: { eq: $hood }) {
      seoMetaTags {
        tags
      }
      ...DatoHoodPage
    }
    mainNavData: datoCmsRoute(slug: { eq: $locale }) {
      ...DatoMainNavTree
    }
    # esimerkkinä query jolla noudettavissa yhden alueen asuntoja
    tampuuri {
      rentalProperties: property(where: { hood: { _eq: $hood }, housing_types: { _eq: "rental" } }) {
        ...TampuuriPropertyCard
      }
      studentProperties: property(where: { hood: { _eq: $hood }, housing_types: { _eq: "student" } }) {
        ...TampuuriPropertyCard
      }
      seniorProperties: property(where: { hood: { _eq: $hood }, housing_types: { _eq: "senior" } }) {
        ...TampuuriPropertyCard
      }
    }
  }
`
