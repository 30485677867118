import React from "react"
import { useTranslation } from "react-i18next"
import placeholderImage from "../../../images/property-placeholder.png"
import * as styles from "./PropertyCard.module.scss"

type PropertyCardProps = Queries.TampuuriPropertyCardFragment

const PropertyCard = (props: PropertyCardProps) => {
  // console.debug("Rendering PropertyCard", props)
  const { t } = useTranslation("asuntosivu")
  const image = props.media[0]
  // const types = props.housing_types ? props.housing_types.split(",") : []
  // const hideStatus = types.indexOf("senior") !== -1

  return (
    <article className={styles.container}>
      <header>
        <span className={styles.address}>{props.street_address}</span>
        <br />
        <span className={styles.hood}>{props.hood}</span>
      </header>
      <div className={styles.image}>
        {image ? (
          <picture>
            <source
              srcSet={`${image.url}?auto=format&crop=center&fit=crop&w=300&h=250 1x, ${image.url}?auto=format&crop=center&fit=crop&w=600&h=500 2x`}
            />
            <img
              src={`${image.url}?auto=format&crop=center&fit=crop&w=300&h=250`}
              alt={t("alt.propertyFacadeThumb", { address: props.street_address })}
              width={300}
              height={250}
              loading="lazy"
            />
          </picture>
        ) : (
          <img src={placeholderImage} alt="" width={300} height={250} />
        )}
        {/* {!hideStatus && props.free_apartments > 0 && <span>{t("Vapaata", "Vapaata")}</span>} */}
        <div className={styles.labels}>
          {/* TODO */}
          {props.rent_min && props.rent_max && (
            <span>
              Vuokra {props.rent_min} - {props.rent_max} €
            </span>
          )}
          <span>Vapaita asuntoja: {props.free_apartments}</span>
        </div>
      </div>
    </article>
  )
}

export default PropertyCard
