import { graphql } from "gatsby"
import React from "react"
import ContentResidentInfo from "./ContentResidentInfo"
import * as styles from "./ContentResidentInfoSection.module.scss"

type ContentResidentInfoSectionProps = Queries.ContentResidentInfoSectionFragment

const ContentResidentInfoSection = ({ residentInfoRefs }: ContentResidentInfoSectionProps) => {
  if (!residentInfoRefs) {
    console.warn("Invalid props for ContentResidentInfoSection")
    return null
  }

  return (
    <section className={styles.container}>
      {residentInfoRefs.map(iref => {
        return iref ? <ContentResidentInfo key={iref.id} {...iref} /> : null
      })}
    </section>
  )
}

export default ContentResidentInfoSection

export const query = graphql`
  fragment ContentResidentInfoSection on DatoCmsResidentInfoSection {
    residentInfoRefs {
      id
      ...ContentResidentInfo
    }
  }
`
