import React, { useCallback, useEffect } from "react"
import * as styles from "./CookieDeclaration.module.scss"

export type CookieDeclarationProps = {
  groupId: string
}

const CookieDeclaration = (props: CookieDeclarationProps) => {
  // console.debug("CookieDeclaration rendering", props)
  const [hasMounted, setHasMounted] = React.useState(false)

  const targetRef = useCallback(
    node => {
      if (node === null) {
        // console.debug("CookieDeclaration adding script to page skipped. No target reference")
        return
      }
      const script = document.createElement("script")
      script.src = `https://consent.cookiebot.com/${props.groupId}/cd.js`
      script.async = true
      node.appendChild(script)
    },
    [props.groupId]
  )

  useEffect(() => {
    // console.debug("CookieDeclaration setting hasMounted to true")
    setHasMounted(true)
  }, [])

  if (!hasMounted || !props.groupId) {
    // console.debug("CookieDeclaration not rendering", hasMounted, props)
    return null
  }

  return (
    <div className={styles.container}>
      <div ref={targetRef}></div>
    </div>
  )
}

export default CookieDeclaration
