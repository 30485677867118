import { graphql } from "gatsby"
import React from "react"
import * as styles from "./ContentCtaButtonIconSection.module.scss"
import ContentIconLink from "./ContentIconLink"

type ContentCtaButtonIconSectionProps = Queries.ContentCtaButtonIconSectionFragment

const ContentCtaButtonIconSection = ({ ctaButtonIconRef }: ContentCtaButtonIconSectionProps) => {
  if (!ctaButtonIconRef) {
    return null
  }

  return (
    <div className={styles.iconLinksContainer}>
      {ctaButtonIconRef.map(
        ctaButtonIcon => ctaButtonIcon && <ContentIconLink key={ctaButtonIcon.id} {...ctaButtonIcon} />
      )}
    </div>
  )
}

export default ContentCtaButtonIconSection

export const query = graphql`
  fragment ContentCtaButtonIconSection on DatoCmsCtaButtonIconSection {
    ctaButtonIconRef {
      id
      ...ContentIconLink
    }
  }
`
