import { graphql } from "gatsby"
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import * as styles from "./ContentImage.module.scss"

type ContentImageProps = Queries.ContentImageFragment

const ContentImage = (props: ContentImageProps) => {
  // console.debug("Rendering ContentImage", props)
  const { image } = props
  if (!image?.gatsbyImageData) {
    console.warn("Invalid props for ContentImage")
    return null
  }
  return (
    <section className={styles.container}>
      <figure className={styles.figure}>
        <GatsbyImage
          className={styles.image}
          alt={image.alt || ""}
          image={image?.gatsbyImageData as unknown as IGatsbyImageData}
        />
        <figcaption>{image.title}</figcaption>
      </figure>
    </section>
  )
}

export default ContentImage

export const query = graphql`
  fragment ContentImage on DatoCmsImage {
    image {
      alt
      title
      gatsbyImageData(layout: CONSTRAINED, height: 500)
    }
  }
`
