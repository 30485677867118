import { graphql } from "gatsby"
import React from "react"
import * as styles from "./ContentResidentInfo.module.scss"

export type ContentResidentInfoProps = Queries.ContentResidentInfoFragment

const ContentResidentInfo = ({ title, paragraph }: ContentResidentInfoProps) => {
  if (!paragraph || !title) {
    console.warn("Invalid props for ContentResidentInfo")
    return null
  }

  return (
    <div className={styles.container}>
      <div className="narrow-content">
        <h2>{title}</h2>
        <div className={styles.content} dangerouslySetInnerHTML={{ __html: paragraph as string }} />
      </div>
    </div>
  )
}

export default ContentResidentInfo

export const query = graphql`
  fragment ContentResidentInfo on DatoCmsResidentInfo {
    title
    paragraph
  }
`
