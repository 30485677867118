import { Link } from "gatsby"
import React from "react"
import PropertyCard from "../molecules/cards/PropertyCard"
import * as styles from "./PropertyCardList.module.scss"

type PropertyCardListProps = {
  properties: readonly Queries.TampuuriPropertyCardFragment[]
  basePath: string
}

function PropertyCardList(props: PropertyCardListProps) {
  return (
    <ul className={styles.list}>
      {props.properties.map(property => {
        if (!property.slug) {
          return null
        }
        return (
          <li key={property.id} className={styles.item}>
            <Link to={`${props.basePath}/${property.slug}`}>
              <PropertyCard {...property} />
            </Link>
          </li>
        )
      })}
    </ul>
  )
}

export default PropertyCardList
