import { graphql } from "gatsby"
import React from "react"
import { useTranslation } from "react-i18next"
import { usePropertyCardData } from "../../../hooks/usePropertyCardData"
import PropertyCardList from "../../organisms/PropertyCardList"

type ContentPropertyCardSectionProps = Queries.ContentPropertyCardSectionFragment

const ContentPropertyCardSection = (props: ContentPropertyCardSectionProps) => {
  // console.debug("Rendering ContentPropertyCardSection", props)

  const propertyIds = props.propertyList ? props.propertyList.split(",").map(id => parseInt(id, 10)) : []
  const properties = usePropertyCardData(propertyIds)

  const { i18n } = useTranslation()
  const basePath = i18n.language === "en" ? "/en/housing" : "/fi/kohteet"

  // console.debug("Rendering ContentPropertyCardSection", properties)'

  const sortedProperties = properties
    .map(property => {
      const index = propertyIds.indexOf(property.id)
      if (index === -1) {
        return
      }
      return {
        ...property,
        position: index,
      }
    })
    .filter(Boolean)
    .sort((a, b) => (a?.position && b?.position ? a.position - b.position : 0))

  return (
    <section>
      <h2>{props.title}</h2>
      {props.ingress && <p className="ingress">{props.ingress}</p>}
      <PropertyCardList properties={sortedProperties} basePath={basePath} />
    </section>
  )
}

export default ContentPropertyCardSection

export const query = graphql`
  fragment ContentPropertyCardSection on DatoCmsPropertyCardSection {
    title
    ingress
    propertyList
  }
`
