import { graphql } from "gatsby"
import React from "react"
// import * as styles from "./ContentCtaCardSection.module.scss"
import ContentCtaCard from "./ContentCtaCard"

export type ContentCtaCardSectionProps = Queries.ContentCtaCardSectionFragment

const ContentCtaCardSection = (props: ContentCtaCardSectionProps) => {
  // console.debug("Rendering ContentCtaCardSection", props)
  return (
    // <section className={styles.cardsContainer}>
    <section>
      {props.ctaBannerRefs?.map(ctaBannerRefs => {
        if (!ctaBannerRefs) {
          return null
        }
        return <ContentCtaCard key={ctaBannerRefs.id} {...ctaBannerRefs} />
      })}
    </section>
  )
}

export default ContentCtaCardSection

export const query = graphql`
  fragment ContentCtaCardSection on DatoCmsCtaCardSection {
    ctaBannerRefs {
      ...ContentCtaCard
    }
  }
`
