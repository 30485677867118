import React from "react"
import Arrow from "../../../images/icons/arrow.inline.svg"
import * as styles from "./IconCard.module.scss"

type IconCardProps = {
  icon: JSX.Element | string
  label: string
}

const IconCard = (props: IconCardProps) => {
  return (
    <div className={styles.container}>
      {props.icon}
      <div className={styles.text}>
        <span>{props.label} </span>
        <span>
          <Arrow />
        </span>
      </div>
    </div>
  )
}

export default IconCard
