import { graphql } from "gatsby"
import React from "react"
import { parseDatoJsonField } from "../../../utils"
import CookieDeclaration from "../../atoms/CookieDeclaration"
// import LajitteluVisaApp from "../../campaigns/jatekuuri/LajitteluVisaApp" // kommentoitu pois
// import styles from "./ContentApplication.module.scss"

type ContentApplicationProps = Queries.ContentApplicationFragment

const ContentApplication = (props: ContentApplicationProps) => {
  // console.debug("Rendering ContentApplication", props)

  const params = parseDatoJsonField<Record<string, string>>(props.params)
  // console.debug("ContentApplication params", params)

  switch (props.identifier) {
    case "cookieDeclaration":
      if (!params?.groupId) {
        return null
      }
      return <CookieDeclaration groupId={params.groupId} />

    default:
      return null
  }
}

export default ContentApplication

export const query = graphql`
  fragment ContentApplication on DatoCmsApplication {
    identifier
    params
  }
`
